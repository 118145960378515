.header-menu {


    //background-color: rgb(234, 245, 253) !important;
    background-color: rgb(234, 245, 253) !important;
    transition      : all 0.5s;

    height: $headerMenuHeight !important;

    position         : fixed;
    z-index          : 1;
    width            : 100%;
    display          : flex;
    justify-content  : center;
    align-items      : center;
    //top            : 40px;
    box-shadow       : 0px 2px 15px rgb(0 0 0 / 10%);
    opacity          : 1;
    transition       : opacity 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}


.logo {
    font-size     : 28px;
    margin        : 0;
    padding       : 0;
   // line-height   : 1;
    //font-weight   : 600;
    //letter-spacing: 0.5px;
   // text-transform: uppercase;

    .logo-picture {
        max-height          : 65px;
        width: 100%;
      //  min-width: 140px !important;
    }
}

.ant-menu-title-content {
    font-size: 16px;
    font-weight: 700;
}
.ant-menu-horizontal .ant-menu-item {
    padding-inline: 10px !important;
}
.container-logo {
    display: flex;
    
    align-items: center;
    height     : calc($headerMenuHeight - 5px);
   // min-width: 200px;
   // width      : 100%;
}

.container-menu {
    //  display: flex;
    //  justify-content: flex-end;
    height: calc($headerMenuHeight - 5px);
    width : 80%;

    .ant-menu-light {
        background-color: transparent !important;
    }

}

.btn-menu {
    height         : calc($headerMenuHeight - 5px);;
    //padding-right: 12px;
    display        : flex;
    justify-content: flex-end;
    align-items    : center;

    svg {
        font-size: 22px;
    }
}

@media (max-width : 1210px) {
    .header-menu-menu {
        display: none;
    }
}

@media (min-width : 1210px) {
    .btn-menu {
        display: none;
    }
}

.menu-drawer {
  
    height        : 100vh !important;
    top           : 0 !important;

    .ant-drawer-body {
        display       : flex !important;
        flex-direction : column !important;
        align-content: center;
        justify-items: center;
        justify-content: center !important;
        align-items    : center !important;
        //text-align: center;
    }
    .ant-drawer-title {
        display: flex;
        justify-content: center;
    }

    .ant-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 18px;

    }
    
}