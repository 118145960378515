body {
    font-family            : $bodyFontDefault;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-family            : $bodyFontDefault;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale; 
}