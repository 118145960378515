.ant-layout{
    display: flex;
}

.ant-layout-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px !important;
    height: $footerHeight;
    text-align: center !important;
    font-weight: 600 !important;
}

.ant-layout-content {
    min-height: $contentHeight !important;
    margin-top: calc($headerInfosHeight + $headerMenuHeight);
    display: flex;
    align-items           : center;
    justify-content       : center;
    flex-direction: column;
}